import React from 'react';

import { Helmet } from 'react-helmet';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const instance = createInstance({
  urlBase: 'https://stats.verbaere.com/',
  siteId: 1,
});

export default () => {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Pierre VERBAERE Portfolio'}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description || 'Pierre VERBAERE Portfolio'} />
      </Helmet>
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    </>
  );
};
